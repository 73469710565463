// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-equipo-index-js": () => import("./../../../src/pages/equipo/index.js" /* webpackChunkName: "component---src-pages-equipo-index-js" */),
  "component---src-pages-fotografos-index-js": () => import("./../../../src/pages/fotografos/index.js" /* webpackChunkName: "component---src-pages-fotografos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-realizadores-index-js": () => import("./../../../src/pages/realizadores/index.js" /* webpackChunkName: "component---src-pages-realizadores-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-equipo-miembro-js": () => import("./../../../src/templates/equipo-miembro.js" /* webpackChunkName: "component---src-templates-equipo-miembro-js" */),
  "component---src-templates-team-categories-js": () => import("./../../../src/templates/team-categories.js" /* webpackChunkName: "component---src-templates-team-categories-js" */)
}

